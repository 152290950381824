/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

// font
@import 'pretendard/dist/web/static/pretendard.css';

/* You can add global styles to this file, and also import other style files */

// flynnlab
@import "../../../libs/client/src/scss/utils-mixin";
@import '../../../libs/client/src/scss/flynnlab-style';
@import '../../../libs/client/src/scss/insta-chat-material';

:root {
  /* Set the background of the entire app */
  //--ion-background-color: #ff3700;

  /* Set the font family of the entire app */
  --ion-font-family: Pretendard, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Roboto', sans-serif;
  --mdc-typography-font-family: var(--ion-font-family);
}

body {
  background-color: var(--ion-background-color);
  color: var(--ion-text-color);
}

.plt-desktop {
  @extend %outer-scrollbar;
}

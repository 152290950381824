a {
  text-decoration: none;
}

ion-toast.hchat-toast {
  --background: #f9fafc;
  --border-color: #e6eaeb;
  --border-style: solid;
  --border-width: 1px;
  --border-radius: 10px;
  --box-shadow: unset;
  --color: #000;

  &::part(container) {
    height: 40px;
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

/* Chrome, Safari, Edge 등 일부 브라우저에서 스핀 버튼 숨기기 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox에서 스핀 버튼 숨기기 */
input[type="number"] {
  -moz-appearance: textfield;
}

ion-modal {
  &.pixel-modal-auto-height {
    --height: auto;
    --max-height: 90vh;
  }
}

ion-modal {
  &.pixel-modal-fullscreen {
    --border-radius: 0;
    --height: 100%;
    --max-height: 100%;
    --width: 100%;
    --max-width: 100%;

    .modal-wrapper {
      height: 100%;
      max-height: 100%;
      width: 100%;
      max-width: 100%;
    }
  }
}

.pixels-storybook {
  width: 420px;
  height: calc(100vh - 100px);
  background: #00a0e9;
  padding: 10px;
  box-sizing: border-box;
}
